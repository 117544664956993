// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-release-js": () => import("./../src/templates/release.js" /* webpackChunkName: "component---src-templates-release-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-js": () => import("./../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-shows-js": () => import("./../src/pages/shows.js" /* webpackChunkName: "component---src-pages-shows-js" */)
}

